/* place card */
.card .card-header {
    padding: 10px;
}
.card .card-content {
    padding: 10px;
}
.card .card-action {
    text-align: right;
}
.place-info {
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-wrap: wrap-reverse;
}
.place-info img {
    max-height: 5em;
}
.place-name {
    font-size: 1.5rem;
    font-weight: 500;
}
.place-name-small {
    font-size: 1.2rem;
    font-weight: 500;
}
.place-name-descr {
    /* 
    The problem is that long description forces whole heading/logo 
    to wrap instead of wrapping itself.
    This somehow fixes it though I don't understand how.
     */
    flex: 1;
    min-height: 92px;
}
.place-name-descr a {
    color: #333;
}
.place-name-descr a:hover .place-name {
    opacity: 0.8;
    
}
.place-description {
    opacity: 0.7;
    line-height: 1.7em;
    /* min height here is commented in favor of place-name-description min height - it's better with long club name */
    /* min-height: 50px; */
}
.place-full-description {
    white-space: pre-wrap;
}
.place-address {
    opacity: 0.85;
    line-height: 1.7em;
    min-height: 5em;
}
.place-full-details > * {
    margin-bottom: 0.7em;
}
.place-full-details > .place-full-description {
    margin-top: 35px;
}

/* search bar */
.search-bar {
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    position: relative;
}
.search-bar input {
    margin: 0px;
    width: 98%;
}
.search-bar i {
    position: absolute;
    top: 16px;
    right: 8px;
}

/* filters */
.filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 0 0 auto;
}
.filters > * {
    margin-right: 5px;
    margin-bottom: 5px;
}
.desktop-filter li {
    margin: 5px 0;
}
.desktop-filter li button {
    width: 100%;
    padding: 0.45rem 0.5rem;
    text-align: left;
    max-width: 165px;
    height: auto;
    line-height: 1.5rem;
}
.desktop-filter li.selected button {
    background-color: aqua;
}

.country-city-control {
    display: flex;
    align-items: center;
    border-radius: 2px;
}
@media (min-width: 768px) {
.country-city-control {
    margin: 1em 0;
}
}
.country-city-control .btn-flat {
    text-transform: unset;
}
.country-city-control button {
    padding:0 1px 0 0.5rem;
    font-size: 1.1em;
}

/* register */
.register-section {
    padding: 1em 0;
    margin: 0 -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: #FCF3FE; */
    /* background-color: #e3f2fd; */
    /* background-color: #e1f5fe7f; */
    background-color: #f9f9f9;
    min-height: 100vh;
}
.register-section > div {
    padding: 1em 0;
}
.register-section .question {
    font-size: 1.2em;
    font-weight: 300;
    margin-top: 0;
    text-align: center;
    word-spacing: 4px;
    padding: 0 1em 1em 1em;
    margin-bottom: auto;
    color: var(--main-color-darker);
}
.register-section .question a {
    color: inherit;
}
.register-section .question i {
    vertical-align: middle;
}
.register-section .register-club {
    margin: auto 0;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* places results card grid */
.places-results {
    display: flex;
    flex-wrap: wrap;
}
.place {
    width: 100%;
    animation: fadein .6s;
}
/* .place+.place {
    margin-right: 10px;
} */

@media (min-width: 480px) {
    .places-results {
        width: 100%;
        /* justify-content: space-between; */
    }
    .place {
        min-width: 220px;
        flex: 0 0 48%;
        margin-right: 1%;
    }
}
@media (min-width: 768px) {
    .place {
        flex: 0 0 290px;
        /* here I set flexgrow to 1 because the last items is always
         InviteYourClub and even if it grows to 2-columns, it's fine */
        /* doesn't work on ipad */
        /* flex: 1 0 48%; */
    }
}
@media (min-width: 1280px) {
    .place {
        min-width: 340px;
        /* flex: 0 0 32%; */
        flex: 0 0 340px;
    }
}

.places-results .card,
.places-results .card-panel {
    min-height: 262px;
}
.coach .card-panel {
    min-height: 288px;
}
.places-results .card-panel .invite-your-club {
    display: flex;
    justify-content: center;
    min-height: 200px;
    align-items: center;
    text-align: center;
    opacity: 0.7;
}

.dashboard h1, .dashboard h2 {
    opacity: 0.75;
}

.our-partners {
    text-align: center;
    width: 100%;
    padding: 2.5em 0;
}

/* general layout */
/* mobile */
.dashboard {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 64px - 80px);
}
/* .places {
} */
.filters {
    flex: 0 0 auto;
}
.my-bookings-panel {
    flex: 0 0 auto;
}
.mobile-filter, .mobile-only {
    display: inherit;
}
.desktop-filter, .desktop-only {
    display: none;
}
.search-bar.desktop {
    display: none;
}

/* desktop */
@media (min-width: 768px) {
    .dashboard {
        flex-direction: row;
    }
    .my-bookings-panel {
        order: 2;
        flex: 0 0 270px;
    }
    .filters {
        margin-right: 1.5em;
    }
    .my-bookings-panel {
        margin-left: 1em;
    }

    .filters {
        flex-direction: column;
    }
    .mobile-filter, .mobile-only {
        display: none;
    }
    .desktop-filter, .desktop-only {
        display: inherit;
    }

    .search-bar {
        max-width: 500px;
    }
    .search-bar.mobile {
        display: none;
    }
    .search-bar.desktop {
        display: inherit;
    }
}
