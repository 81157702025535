.ask-about-notifications {
    padding: 8px;
    max-width: 700px;
}
.ask-about-notifications .card {
    z-index: 2005;
}
.ask-about-notifications .title {
    display: flex;
    align-items: center;
    line-height: 1.5em;
    font-size: 1.7em;
    opacity: 0.7;
}
.ask-about-notifications .card-action {
    text-align: right;
}
.ask-about-notifications .card-action .btn {
    padding: 0 1rem;
}
.ask-about-notifications p {
    line-height: 1.8em;
    opacity: 0.9;
    margin: 0.5em !important;
}