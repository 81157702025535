
.how-it-works-hint.expanded {
    margin-top: 1em;
}
.how-it-works-hint {
    word-spacing: 4px;
    opacity: 0.85;
}
.how-it-works-hint button {
    padding: 0;
}