.side-nav-container {
    display: flex;
    flex: 1 0 auto;
}
.side-panel {
    flex: 0 0 200px;
}
@media only screen and (min-width: 992px) {
.main-panel {
    flex: 1 0 auto;
}
.main-panel.capped .main-panel-inner {
    max-width: 1256px;
    flex: 1 0 auto;
}
}
@media only screen and (max-width: 991px) {
.main-panel {
    width: 100%;
}
.main-panel.capped .main-panel-inner {
    width: 100%;
}
}

.main-panel.capped {
    display: flex;
    justify-content: center;
    align-items: start;
}

.side-panel {
    background-color: #5c6bc0;
    margin: 10px;
    border-radius: 3px;
}

.side-panel li {
    opacity: 0.9;
    margin: 5px;
    border-radius: 3px;
}
.side-panel li a {
    display: block;
    padding: 1em;
    font-size: 1.2rem;
    color: white;
}

.side-panel li.active {
    background-color: #e8eaf6;
    opacity: 1;
}
.side-panel li:hover:not(.active) {
    background-color: #7986cb;
    opacity: 1;
}
.side-panel li.active a {
    color: #3f51b5;
    font-weight: 500;
}

.main-panel {
    padding: 1em;
}  
