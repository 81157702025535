.booking-info {
    position: relative;
    width: 100%;
}
.my-bookings .collapsible-body {
    padding: 1rem 0.5rem;
    line-height: 2.6em;
}

.too-late-to-cancel {
    padding-left: 1em;
}
.too-late-to-cancel .description {
    opacity: 0.8;
}
.too-late-to-cancel > * {
    margin: 0.5em 0;
}
