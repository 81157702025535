.notifications-icon {
    position: relative;
}

.badge-corner {
    position: absolute;
    top: 14px;
    left: 14px;
}
.badge-line {
    margin-left: 10px;
    color: white;
    display: inline-block;
}

.notifications-badge {
    background-color: red;
    border-radius: 3px;
    line-height: 1em;
    padding: 3px;
    font-size: 0.8em;
}

nav.has-notifications .button-collapse::after {
    content: "\0000a0";
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: red;
    position: absolute;
    top: 32%;
    right: 0;
}


.admin-site-selector {
    max-height: 64px;
}
.admin-site-selector i {
    margin: 0 5px !important;
    opacity: 0.7;
}
.admin-sites-list {
    font-size: 1.2em;
    margin: 0;
}
.admin-sites-list li > * {
    width: 100%;
    padding: 1em;
}
.admin-sites-list li {
    border-radius: 5px;
    margin: 0.2em;
}
.admin-sites-list li.selected {
    border: solid 1px var(--main-color);
}
.admin-sites-list li:hover {
    background-color: #ddd;
}
.admin-sites-list a {
    display: block;
    text-align: center;
    /* font-size: 1em; */
    color: gray;
}


.brand-logo a {
    display: flex;
    align-items: flex-start;
}