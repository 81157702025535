.warning-not-supported {
    display: flex;
    align-items: center;
    word-spacing: 4px;
    text-align: justify;
}
.warning-not-supported i {
    /* color: #d32f2f; */
    color: #f57f17;
    padding-right: 0.5em;
    font-size: 2em;
}
