.activate-switch {
    padding: 10px;
}

.activate-switch.disabled {
    opacity: 0.7;
}

.activate-switch .switch label {
    font-size: 1em;
}
.activate-switch .switch label input[type=checkbox]:checked + .lever:after {
    background-color: #00c853;
}

.activate-switch .switch label input[type=checkbox]:checked + .lever {
    background-color: #c8e6c9;
}