table.schedule {
    table-layout: fixed;
}
table.schedule th {
    text-transform: capitalize;
}
table.schedule th {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
}
table.schedule th, 
table.schedule td {
    /* color: #555; */
    color: #4e4e4e;
    text-align: center;
    line-height: 2.5em;
    padding: 0.1em;
}
table.schedule th.weekday-0, 
table.schedule th.weekday-6 {
    background-color: #e0e0e0;
    /* background-color: #b3e5fc; */
    /* background-color: #bbdefb; */
    /* background-color: #ffe0b2; */
    border-left: white 2px solid;
    border-right: white 2px solid;
}
table.schedule td > div:hover {
    box-shadow: 0 0 1em gray;
}
table.schedule td > div {
    /* width: 98%; */
    /* height: 98%; */
    cursor: pointer;
    position: relative;
    padding: 1px 2px;
}
table.schedule .all-busy, 
table.schedule .passed {
    background-color: #e8e6e6;
    color: #777
}
table.schedule .available {
    /* background-color: #1de9b6; */
    background-color: #69f0ae;
}
table.schedule .available.free-3 {
    background-color: #64ffda;
}
table.schedule .available.free-2 {
    background-color: #a7ffeb;
}
table.schedule .available.free-1 {
    background-color: #b2dfdbd3;
}
table.schedule .overbooked {
    background-color: red;
    color: white;
    font-weight: 500;
}
table.schedule .cell-hour, 
table.schedule .cell-capacity, 
table.schedule .cell-price {
    margin: 0 0.1em;
}
table.schedule .cell-capacity {
    opacity: 0.8;
    font-style: italic;
}

table.schedule td .notifications-badge {
    position: absolute;
    top: 1px;
    right: 1px;
    color: white;
}

table.schedule td.total-stat .total-stat-label {
    display: none;
}
table.schedule td.total-stat:first-child .total-stat-label {
    display: inline-block;
    position: absolute;
    left: 1em;
    font-style: normal;
}
table.schedule td.total-stat {
    opacity: 0.5;
    font-style: italic;
    font-size: 0.9rem;
    padding-top: 12px;
}

/* .ov-timetable {
} */

.ov-cell {
    /* width: 9rem; */
    width: 148px;
    /* min-width: 150px; */
    padding: 0.5rem;
    margin: 1px;
    position: relative;
    opacity: 0.95;
    border-radius: 2px;
}
.ov-cell .ov-ts-title {
    font-size: 0.95rem;
}
.ov-free .ov-ts-title {
    text-align: center;
    padding: 0.4em 0;
}

.ov-free .ov-hour, 
.ov-busy .ov-hour {
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
    line-height: 1.5rem;
}
.ov-cell.ov-booking {
    padding: 0.3rem;
    border-radius: 0;
}
.ov-booking .ov-hour, .ov-booking .ov-ts-title {
    font-size: 0.8rem;
    opacity: 0.8;
    color: white;
}
.ov-booking .ov-info {
    width: 100%;
    /* text-align: center; */
    vertical-align: middle;
    /* line-height: 1.7rem; */
    /* padding: 0.4rem; */
    /* padding-top: 0.1rem; */
    font-weight: 500;
    /* font-size: 1.1rem; */
    color: white;
}
.ov-free {
    /* background-color: #e8e6e6; */
    /* background-color: #c8e6c9; */
    /* background-color: #a2ffdd; */
    /* background-color: #b9f6ca; */
    /* background-color: #e8f5e9; */
    /* background-color: #f5f5f5; */
    background-color: #1de9b6c4;
}
.ov-booking {
    /* background-color: #4fc3f7; */
    /* background-color: #9fa8da; */
    /* background-color: #5c6bc0; */
    background-color: #7986cb;
    /* background-color: #9fa8da; */
}
.ov-pending {
    /* background-color: #ff5252; */
    background-color: #f44336;
    color: white;
}
.ov-missed {
    background-color: #bf360c;
    color: white;
}
.ov-overbooked {
    background-color: red !important;
    color: white;
    font-weight: 500;
}
.ov-passed {
    opacity: 0.7;
}
.ov-passed.ov-free {
    background-color: #e8e6e6;
    color: #777;
}
.ov-busy {
    background-color: #e8e6e6;
    opacity: 0.1;
}
.ov-highlight {
    /* background-color: red !important; */
    /* background-color: red !important; */
    /* background-color: #a2ffdd; */
    background-color: #1de9b6c4;
    /* opacity: 1 !important; */
    /* box-shadow: 0px 0px 5px 5px #fff; */
}
.ov-dim {
    /* background-color: #555 !important;
    opacity: 0.3; */
    /* background-color: #fff !important; */
    /* color: #999 !important; */
    opacity: 0.15 !important;
}
.ov-cell.ov-merge-right {
    margin-right: 0;
    width: 149px;
}
.ov-cell.ov-merge-left {
    margin-left: 0;
    width: 149px;
}
.ov-cell.ov-merge-top {
    margin-top: -1px;
}
.ov-cell.ov-merge-left .ov-hour,
.ov-cell.ov-merge-left .recurring-icon,
.ov-cell.ov-merge-left .ov-info,
.ov-cell.ov-merge-top .ov-hour,
.ov-cell.ov-merge-top .recurring-icon,
.ov-cell.ov-merge-top .ov-info 
{
    opacity: 0;
}

.ov-hour-slots {
    display: flex;
    flex-wrap: wrap;
}

.ov-cell:hover:not(.ov-busy):not(.ov-dim) {
    box-shadow: 0px 0px 3px 1px #ccc;
    opacity: 1;
    cursor: pointer;
}

.ov-cell .recurring-icon {
    position: absolute;
    top: 0.1rem;
    right: 0.1rem;
    opacity: 0.8;
}

@media only screen and (max-width: 992px) {
    
}

@media only screen and (max-width: 600px) {
.ov-timetable {
    margin: 0;
}
/* .ov-hour-slots {
    flex-wrap: wrap;
} */
/* .ov-cell {
    width: 145px;
} */
}
