@media only screen and (min-width: 420px) {
.booking-status-floating {
    position: absolute;
    top: 0px;
    right: 0px;
}
}
/* @media only screen and (max-width: 420px) {
.pending-approval {
    position: absolute;
    top: 0px;
    right: 0px;
}
} */

.booking-status {
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px 0;
    /* background-color: #f57c00; */
    /* color: white; */
    /* padding: 5px 10px; */
    display: inline-block;
    margin: 5px 0;
    /* border-radius: 2px; */
}


.pending-approval {
    color: #f57c00;
}
.booking-confirmed {
    color: #00e676;
}
.booking-rejected, .booking-missed {
    color: #bf360c;
}
.booking-canceled {
    color: grey;
}
