.site-footer.page-footer {
    padding-top: 0;
}
.site-footer > .container {
    display: none;
}
.site-footer .links a, .site-footer .contact-us a {
    text-align: right;
    font-weight: 500;
}
.site-footer.page-footer .footer-copyright, .site-footer .links a, .site-footer .contact-us a {
    background-color: #fafafa;
    color: #777 !important;
}

.site-footer .contact-us {
    display: flex;
    align-items: center;
    flex-direction: row;
}
.site-footer .contact-us a {
    display: flex;
    align-items: center;
    margin: 0 0.5em;
}
.site-footer .contact-us a > * {
    padding: 0 2px;
}
.site-footer .contact-us a:hover {
    color: #039be5 !important;
    transition-duration: 0.5s;
}

.site-footer .contact-us .facebook-icon,
.site-footer .contact-us .facebook-icon img {
    background-color: #777;
    border-radius: 5px;
    height: 20px;
    width: 20px;

}