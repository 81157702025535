.watcher-config .watcher-description {
    padding: 1em 0;
    opacity: 0.9;
    font-size: 1.1em;
}
.watcher-config .watcher-panes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.watcher-config .watcher-params > div {
    padding: 0.3em 0;
}
.watcher-config .watcher-controls {
    margin-top: 1.2em;
}
.watcher-config .watcher-params {
    margin-right: 0.5em;
    padding: 1em 0;
}

.watcher-config .time-controls,
.watcher-config .date
{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-around; */
}
.watcher-config .time-controls {
    text-align: center;
}
.watcher-config .time-controls > div:first-child {
    margin-right: 1.3em;
}
.watcher-config .watcher-time-period {
    display: flex;
    font-size: 1.3em;
}
.watcher-config .watcher-time-period > * {
    margin-right: 0.4em;
}

.watcher-config .date {
    font-size: 1.3em;
}

.watcher-config .watcher-clubs li {
    /* vertical-align: middle; */
    padding: 0.3em 0;
} 
.watcher-config .watcher-clubs label {
    color: gray;
} 

.watcher-config .watcher-clubs .logo-small {
    height: 22px;
    vertical-align: middle;
    border-radius: 3px;
    margin-right: 10px;
}

.stop-watch-button {
    background-color: #D82020 !important;
    opacity: 0.8;
}
.start-watch-button {
    background-color: #4caf50 !important;
    opacity: 0.8;
}