.change-phone-form {
    position: relative;
    margin-bottom: 2rem;
}
.change-phone-form .input-field {
    width: 220px;
}
.change-phone-form  .error {
    color: red;
}
.change-phone-form .change-phone-form-actions {
    position: absolute;
    bottom: -2.8rem;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
}
