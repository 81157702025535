
.increase-decrease-control button {
    padding: 0 0.4em;
}
.increase-decrease-control {
    font-size: large;
    padding: 0.1rem;
    vertical-align: middle;
}
.increase-decrease-control .incr-decr-value {
    display: inline-block;
}