.day-switcher {
    display: inline-block;
    /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); */
}
.day-switcher button {
    padding: 0 1.5em;
    margin: 1px;
}
.compact.day-switcher button {
    padding: 0 0.5em;
    margin: 0px;
}
.compact.day-switcher.warning-highlight {
    background-color: #ef9a9a;
    border-radius: 3px;
}

@media only screen and (max-width: 420px) {
.day-switcher {
    display: flex;
    justify-content: stretch;
    margin: 0.5em 0;
    padding: 0.5em 0em;
}
.day-switcher button {
    flex-grow: 1;
}
.day-switcher button.today {
    flex-grow: 2;
}
}