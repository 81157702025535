.partners {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.partners > div {
    margin: 2.5em;
}
.partners img {
    opacity: 0.9;
}
