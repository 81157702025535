.navbar-notifications .dropdown-content {
    /* width: 550px; */
    min-width: 550px;
}

.notification {
    padding: 10px;
    margin: 1px;
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
}
.notification a, .see-all a {
    display: inline;
    color: #039be5;
    padding: 0;
}
.notification button {
    margin: 2px;
    padding: 0 1.5rem;
}
.notification .description {
    /* min-width: 150px; */
}


.notification .commands {
    /* min-width: 265px; */
    text-align: center;
}

.accept {
    /* background-color: #43a047; */
    background-color: #00c853 !important;
}
.reject {
    /* background-color: #e53935; */
    background-color: #d50000 !important;
}

.see-all {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    font-weight: bolder;
}

.notifications-list .notification {
    margin: 5px;
    min-height: 75px;
    line-height: 1.7rem;
}
