.ls-dropdown .ls-button {
    opacity: 0.99;
}
.ls-dropdown .ls-option {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.ls-dropdown img {
    vertical-align: middle;
}

.ls-panel {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    max-width: 150px;
}
.ls-panel .ls-option {
    display: inline-block;
    padding: 5px;
}