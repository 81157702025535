.booking-detail {
    line-height: 2em;
    font-size: 1.2em;
    display: flex;
    justify-content: space-between
}
@media only screen and (max-width: 420px) {
.booking-detail {
    flex-direction: column;
}
}
.booking-detail .description {
    opacity: 0.8;
    font-size: 1.1rem;
    padding: 0.5rem 0;
    white-space: pre-wrap;
}

.completed.booking-detail {
    opacity: 0.7;
}


.control-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1em;
}
@media only screen and (max-width: 420px) {
.control-panel {
    padding-bottom: 1em;
    flex-wrap: wrap;
}
.control-panel > div {
    flex: 1 0 auto;
    padding: 0 5px;
}
}

.site-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 2em;
}
.site-heading h1 {
    display: flex;
    align-items: center;
}
.site-info-section {
    margin: 3em 0;
    padding: 1em;
}

.site-info {
    line-height: 2em;
    opacity: 0.9;
    margin-bottom: 2em;
}
.site-info .site-full-description {
    white-space: pre-wrap;
}
.site-info .site-short-description {
    opacity: 0.7;
    padding-bottom: 0.5em;
}
.site-info .contacts > div {
    margin-bottom: 0.4em;
}
.site-map > div {
    margin-bottom: 1em;
}

/* site info and details responsiveness and spacing */
.site-info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.site-info > div {
    margin-bottom: 2em;
}
.site-info .site-map {
    flex: 1 0 auto;
}
/* @media only screen and (min-width: 568px) { */
@media only screen and (min-width: 681px) {
.site-info {
    flex-direction: row;
    flex-wrap: wrap;
}
.site-info > div {
    margin-right: 2.5em;
    min-width: 15%;
}
.site-info > div:last-child {
    margin-right: 0;
}
.site-info .site-details.with-description,
.site-info .site-details.with-map  {
    flex: 1 0 320px;
}
.site-info > .site-full-description {
    flex: 1 0 320px;
}
}

.not-enough-capacity-banner {
    padding: 5px;
    background-color: lightsalmon;
    color: white;
    font-size: 1.1rem;
}

.limit-site .how-it-works-hint {
    margin: 1.2em 0;
}
.limit-site-contacts {
    line-height: 2.5em;
    margin-top: 2em;
    padding: 0.5em 1em;
    border-left: 2px #ccc solid;
}
.limit-site-contacts .contact-the-place-text {
    opacity: 0.85;
}

.post-complete-message {
    font-size: 1.2em;
    border-left: orange solid 3px;
    padding-left: 1em;
    margin-top: 0.5em;
    word-spacing: 3px;
}
.post-complete-message a {
    color: #448aff;
}

.fadein-animation {
    animation: fadein 1s;
}
.short-fadein-animation {
    animation: fadein .6s;
}

.booking-confirm {
    font-size: 1.3em;
    font-weight: 500;
    padding: 0.5em 0;
}
.booking-confirm.booking-confirm-pending {
    color: #f57c00;
    font-size: 1.35em;
}
.booking-confirm.booking-confirm-success {
    color: #0ca95d;
}

.pending-status-clarification .how-it-works-hint button {
    opacity: 0.7;
    margin-bottom: 2em;
}
.pending-status-clarification .how-it-works-hint.expanded > * {
    margin: 1em 0;
}
.pending-status-clarification .site-contacts {
    line-height: 2.5em;
    margin: 1em;
}

.no-online-booking {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    line-height: 3em;
    color: #777;
    font-size: 1.2em;
}

.site-tags {
    padding: 2em;
}
.site-tags ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.site-tags ul li {
    padding: 0.5em;
    margin: 0.5em;
    font-size: 1.2em;
    opacity: 0.85;
}
.site-tags ul li:hover {
    opacity: 1;
    /* font-size: 1.25em; */
}
.site-tags ul li::before {
    content: "#";
    color: #448AFF;
    /* color: var(--main-color); */
}
.site-tags ul li a {
    /* color: var(--main-color); */
}

.site-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5em;
}
.site-gallery > * {
    margin: 1em;
}

.watch-button, .watch-button:focus {
    opacity: 0.75;
    position: absolute;
    right: -1px;
    top: 5px;
    height: 25px;
    padding: 0 2px;
    margin-right: 4px;
    color: #03a791;
    background-color: white;
    border-radius: 3px;
}
.watch-button.has-watcher {
    color: #f57f17;
}
.watch-button:hover {
    opacity: 1;
    scale: 1.1;
}
.site-detail table.schedule .all-busy {
    padding: 0 24px;
}

.watch-suggestion {
    font-size: 1.2em;
    padding: 7px 0 7px 7px;
    margin: 1em 0;
    word-spacing: 3px;
    border-left: #0ca95d solid 3px;
}

.user-booking-icon {
    position: absolute;
    left: 3px;
    top: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background-color: white;
}
.user-booking-icon i {
    color: #fbc02d;
}

#add-another-booking-button {
    float: left;
    margin-left: 18px !important;
}

