
.time-range-control {
    display: flex;
    margin: 5px;
}
.time-range-control .time-box button {
    padding: 0 0.4em;
}
.time-range-control .time-box {
    display: inline-block;
    /* background-color: red; */
}
.time-range-control .time-box + .time-box {
    margin-left: 20px;
}
.time-range-control .label {
    opacity: 0.5;
    /* vertical-align: top; */
    text-align: center;
    /* background-color: grey; */
}
.time-range-control .time {
    font-size: large;
    padding: 0.5rem 0;
    vertical-align: middle;
    text-align: center;
    /* background-color: white; */
    /* border-radius: 3px; */
}
.time-range-control .end-time {
    display: inline-block;
}

.time-range-control .time-box .input-field {
    margin-top: -3px;
}
.time-range-control .time-box input {
    font-size: 1.2rem;
    padding-left: 20px;
}
.time-range-control .time-box .time-dropdown {
    width: 90px;
}
.time-range-control .time-box .time-dropdown .dropdown-content {
    max-height: 300px;
}
.time-range-control .time-box .time-dropdown .dropdown-content li.selected {
    background-color: #ddd;
}
.time-range-control .time-box img {
    height: 20px;
    width: 20px;
    margin: 14px 12px 0 0;
}
