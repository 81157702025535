.fm-selector {
    /* background-color: #eee; */
    /* border-radius: 3px; */
    /* display: inline-block; */
    /* text-align: right; */
}

.fm-selector button {
    border-radius: 3px;
    padding: 0 5px 0 10px;
    width: 100%;
}
.fm-selector-with-background button {
    background-color: #eee;
}
.fm-selector.fm-selected button {
    background-color: #00e5ff;
}
.fm-selector .arrow-drop-down {
    font-size: 0.75rem;
    margin: 0 3px;
    opacity: 0.9;
}

.fm-selector img {
    height: 20px;
    width: 20px;
}
