.invitation-login {
    max-width: 400px;
    padding: 2em;
}
.invitation-login > *,
.invitation-login-ask-email > * {
    margin: 0.5em 0;
}
.invitation-login .error {
    color: red;
}

.invitation-login-ask-email {
    font-size: 1.2em;
    text-align: center;
    max-width: 400px;
}
