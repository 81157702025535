.why-bg-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.why-card {
    flex: 0 0 95%;
    background-color: white;
    padding: 1em;
    margin: 1em;
    border-radius: 3px;
    opacity: 0.9;
    /* text-align: center; */
    line-height: 1.8em;
    word-spacing: 4px;
    display: flex;
    flex-direction: column;
}
.why-card .why-card-icon {
    text-align: center;
    margin: 1em;
    color: #5c6bc0;
}
.why-card .why-card-heading {
    font-weight: 500;
    font-size: 1.1rem;
    text-align: center;
    min-height: 3.5em;
}
.why-card .why-card-content {
    flex: 1 0 auto;
    line-height: 2em;
    margin: 1em 0;
    text-align: center;
}
.why-card .why-card-content li {
    margin: 0.7em 0;
}
.why-card .why-card-content li::before {
    /* content: '•';
    padding: 0.5em; */
}
.why-card .why-card-action {
    text-align: center;
}
.why-card .why-card-action a {
    color: #5c6bc0;
}
.why-card .why-card-action a:hover {
    color: white;
    background-color: #5c6bc0;
    padding: 0.5em 1em;
    border-radius: 3px;
    transition-duration: 250ms;
}
.why-card:hover {
    opacity: 1;
}

@media (min-width: 421px) {
.why-card {
    flex: 0 0 300px;
}
}

.whys {
    margin: 3em 0;
}
.whys > * {
    /* margin: 2em 0; */
    padding: 0.5em 0;
}
.whys .why-strong {
    font-size: 1.2em;
    font-weight: 500;
}
.whys p {
    font-size: 1.1em;
    color: #555;
}
