.cancel-button:hover, .cancel-do-nothing-button:hover {
    background-color: lightgrey;
}

.cancel-confirm-button {
    background-color: #D82020 !important;
    opacity: 0.8;
}
.cancel-confirm-button:hover {
    background-color: #D82020 !important;
    opacity: 1;
}
.cancel-recurring-checkbox {
    padding: 0.5rem;
    display: inline-block;
}